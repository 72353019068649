import { Injectable } from '@angular/core'
import { Server } from './server'
import { VehicleInfo } from '@boa/booster-types'
import { FindOptions } from '@boa/app/_shared/types/find-options.model'
import { Observable } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class VehicleInfoService {
  constructor(private server: Server) {}

  find(options: FindOptions) {
    return this.server.get(`/v2/boa/VehicleInfo`, options)
  }

  findById(id: string) {
    return this.server.get(`/v2/boa/VehicleInfo/${id}`)
  }

  create(options: VehicleInfo): Observable<VehicleInfo> {
    return this.server.post(`/v2/boa/VehicleInfo`, options)
  }

  findCommon() {
    return this.server.get(`/v2/common/VehicleInfo`)
  }

  deleteById(id: string) {
    return this.server.del(`/v2/boa/VehicleInfo/${id}`)
  }

  update(
    _id: string,
    params: FormData | VehicleInfo
  ): Observable<{ vehicleInfo: VehicleInfo }> {
    return this.server.post(`/v2/boa/VehicleInfo/${_id}`, params)
  }
}
